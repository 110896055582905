import React from 'react';
import { base_storage_path } from 'src/metadata_heb1';
import './sec-best.css';

const gif_base_path = `${base_storage_path}/gifs`;

function SecCourse() {
    return (
        <section className="sec-best bg-green-light py-4 relative">
            <div id="SecCourse" className="anchor"></div>
            <div className="container relative">
                <div className="container desk-pl-1 text-center items-center justify-between max-w-670">
                    <h2 className="title-42">איך נכנסים, נהנים ולומדים?</h2>

                    <div className="course_ins_wrap">
                        <p> 1. לאחר ההרשמה תקבלו מייל פרטי התחברות </p>
                        <img className="course-gif" src={`${gif_base_path}/course1.gif`} alt="הסבר"></img>
                    </div>

                    <div className="course_ins_wrap">
                        <p>
                            2. נכנסים לשיעור ראשון ומתחילים לבצע משימות מעניינות שיעזרו לרודי הארנב ובילי החתולה להתקדם
                            במסע שלהם.
                        </p>
                        <img className="course-gif" src={`${gif_base_path}/course2.gif`} alt="הסבר"></img>
                    </div>

                    <div className="course_ins_wrap">
                        <p>
                            3. במהלך השיעורים הילדים ידברו איתי במיקרופון, יציירו ציורים, יצלמו, יכתבו ועוד. כל העבודות
                            שלהם יישמרו בתיק עבודות מיוחד באזור האישי שלהם.
                        </p>
                        <img className="course-gif" src={`${gif_base_path}/course3.gif`} alt="הסבר"></img>
                    </div>
                    <div className="course_ins_wrap">
                        <p>
                            4. כדי להתקדם במסע, על ילדיכם לפתור חידות ולענות תשובות נכונות. במידה וילדיכם ענו 3 פעמים
                            תשובה לא נכונה, הם יעברו אוטומטית לקטע הבא על מנת להימנע מתחושת כישלון.{' '}
                        </p>
                        <img className="course-gif" src={`${gif_base_path}/course4.gif`} alt="הסבר"></img>
                    </div>
                    <div className="course_ins_wrap">
                        <p>5. בסיום כל שיעור, ייפתח השיעור הבא וילדיכם יוכלו להמשיך במסע 🙂</p>
                        <img className="course-gif" src={`${gif_base_path}/course5.gif`} alt="הסבר"></img>
                    </div>
                    <div className="course_ins_wrap">
                        <p>6. בונוס: מגוון שיעורים, משחקים ומשימות נוספות באתר</p>
                        <img className="course-gif" src={`${gif_base_path}/course6.gif`} alt="הסבר"></img>
                    </div>
                    <div className="course_ins_wrap">
                        <p>7. בסיום הקורסים ילדכם יקבל ממני הפתעה 😉</p>
                        <img className="course-gif" src={`${gif_base_path}/course7.webp`} alt="הסבר"></img>
                    </div>

                    <p>8. ניתן לחזור על כל קטע בקורסים כמה פעמים שתרצו</p>
                    <p>לטעות זה חשוב! מטעויות לומדים, ממש כך!</p>
                    <p>הסיפורים הדיגיטלים שלנו מאפשרים לילדים לטעות ולתקן. להתאמן ולנסות. שוב ושוב, ושוב.</p>
                </div>
            </div>
        </section>
    );
}

export default SecCourse;
